import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { PushNotificationControllerService, SubscribeUnsubscribeTopicRequest } from 'projects/notification-swagger-lib';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment.local';
import { AuthService } from './auth.service';
import { SharedService } from './shared.service';

@Injectable()
export class NotificationService {
  currentMessage = new BehaviorSubject(null);
  public detedtedOsDevice: any;
  public newToken: any;
  public oldToken: any;
  public loggedInUser: any;

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private pushNotificationControllerService: PushNotificationControllerService,
    private authService: AuthService,
    private sharedService: SharedService
  ) {
    this.authService.user.subscribe(res => {
      this.loggedInUser = res;
    });
  }

  public subscribeToTopic(data: SubscribeUnsubscribeTopicRequest) {
    this.pushNotificationControllerService.subscribeToTopicUsingPOST(data).subscribe(res => { });
  }

  public unsubscribeToTopic(data: SubscribeUnsubscribeTopicRequest): void {
    if (data) {
      this.pushNotificationControllerService.unsubscribeFromTopicUsingPOST(data).subscribe(res => { });
    }
  }

  requestPermission(userTopic = false, installationIdTopic = false): void {
    this.angularFireMessaging.requestPermission.subscribe((res) => {
      this.angularFireMessaging.requestToken.subscribe((token) => {
        let installationIdTopicRequest: SubscribeUnsubscribeTopicRequest;
        let authenticatedUserTopic: SubscribeUnsubscribeTopicRequest;

        if (userTopic) {
          authenticatedUserTopic = {
            registrationId: token,
            topic: this.loggedInUser.sub,
            type: 'fcm'
          };
        }

        if (installationIdTopic) {
          installationIdTopicRequest = {
            registrationId: token,
            topic: sessionStorage.installationId,
            type: 'fcm'
          };
        }

        if (sessionStorage.fcm !== token) {
          if (sessionStorage.fcm) {
            this.unsubscribeToTopic(authenticatedUserTopic);
            this.unsubscribeToTopic(installationIdTopicRequest);
          }
        }
        if (userTopic) {
          this.subscribeToTopic(authenticatedUserTopic);
        }
        if (installationIdTopic) {
          this.subscribeToTopic(installationIdTopicRequest);
        }
        sessionStorage.setItem('fcm', token);
      });
    }, (err) => {
      console.error('Unable to get permission to notify.', err);
    });
  }

  receiveMessage(): void {
    this.angularFireMessaging.messages.subscribe(payload => {
      this.currentMessage.next(payload);
    });
  }
}
