import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { TokenControllerService } from 'projects/auth-swagger-lib';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private tokenControllerService: TokenControllerService,
    private toastrService: ToastrService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.includes('/submit-reset')) {
      request = this.addResetPasswordToken(request);
    }

    if (!request.url.includes('/register')
      && !request.url.includes('/token')
      && !request.url.includes('/request-reset')
      && !request.url.includes('/topics/subscribe')
      && !request.url.includes('/topics/unsubscribe')
      && !request.url.includes('/submit-reset')) {
      request = this.addAccessToken(request);
    } else if (request.url.includes('/register')
      || request.url.includes('/token')
      || request.url.includes('/request-reset')
      || request.url.includes('/topics/subscribe')
      || request.url.includes('/topics/unsubscribe')) {
      request = this.addBase64LoginTokens(request);
    }

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      // if (event['body'] && (event['body'].code === 201 || event['body'].code === 200) && typeof event['body'].content === 'string') {
      //   const successMsg = event['body'].content.charAt(0).toUpperCase() + event['body'].content.slice(1);
      //   if (successMsg !== 'OK') {
      //     if (successMsg === 'Created') {
      //       this.toastr.open('Changes were successfully saved', '', {
      //         duration: 3000,
      //         panelClass: ['success-snackbar']
      //       });
      //     } else {
      //       this.toastr.open(successMsg, '', {
      //         duration: 3000,
      //         panelClass: ['success-snackbar']
      //       });
      //     }
      //   }
      // }
      if (event instanceof HttpResponse) {
      }
    }), catchError((error: any) => {
      if ((error.status === 401 || error.status === 403) && !request.url.includes('/request-reset')) {
        if (request.url.includes('/token') && request.method === 'PUT') {
          this.authService.logout();
          return throwError(error);
        }

        if (this.authService.isAccessTokenExpired && !this.authService.isRefreshTokenExpired) {
          return this.tokenControllerService.refreshTokenUsingPUT({ refreshToken: this.authService.refreshToken }).pipe(switchMap(res => {
            this.authService.setSession(res);
            return next.handle(this.addAccessToken(request));
          }), catchError(refreshError => {
            if (refreshError instanceof HttpErrorResponse) {
              if (refreshError.status === 401 || error.status === 403) {
                this.authService.logout();
              }
            }
            return throwError(refreshError);
          }));
        } else {
          this.authService.logout();
        }
      }

      if (error.error && error.error.message && error.error.message !== 'token verifying failed') {
        // const errorMsg = error.error.message.charAt(0).toUpperCase() + error.error.message.slice(1);
        // this.toastr.open(errorMsg, '', {
        //   duration: 3000,
        //   panelClass: ['error-snackbar']
        // });
      } else {
        this.toastrService.warning('', 'Oops, something went wrong. Please contact us if this keep happening', { positionClass: 'toast-bottom-full-width' });
      }
      return throwError(error);
    })
    ) as Observable<HttpEvent<any>>;
  }

  private addAccessToken(req: HttpRequest<any>): any {
    const requestHeaders = req.headers.set('Authorization', `${sessionStorage.tokenType} ${this.authService.accessToken}`);

    if (req.url.includes('uploads/')) {
      return req.clone({
        headers: requestHeaders,
        responseType: 'blob' as any | Blob
      });
    } else {
      return req.clone({
        headers: requestHeaders
      });
    }
  }

  private addBase64LoginTokens(req: HttpRequest<any>): any {
    return req.clone({
      setHeaders: {
        Authorization: `Basic ${btoa(`${environment.clientId}:${environment.clientSecret}`)}`
      }
    });
  }

  private addResetPasswordToken(req: HttpRequest<any>): any {
    return req.clone({
      setHeaders: {
        Authorization: `${sessionStorage.reset_token}`
      }
    });
  }
}
