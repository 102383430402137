import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenResponse } from 'projects/auth-swagger-lib';
import { BehaviorSubject, Subject } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwtService: JwtHelperService;
  public user: Subject<any> = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private sharedService: SharedService
  ) {
    this.jwtService = new JwtHelperService();

    if (!this.isAccessTokenExpired()) {
      this.user.next(this.jwtService.decodeToken(this.accessToken));
    }
  }

  public validate(): any {
    if (!this.isAccessTokenExpired()) {
      this.user.next(this.jwtService.decodeToken(this.accessToken));
    }
  }

  public logout(): any {
    this.user.next(null);
    sessionStorage.clear();
    localStorage.clear();
    if (!sessionStorage.installationId) {
      sessionStorage.setItem('installationId', this.sharedService.generateRandomCharracters(64).substr(0, 64));
    }
    this.router.navigate(['/login']);
  }

  public setSession(token: TokenResponse): any {
    if (!this.jwtService.isTokenExpired(token.accessToken)) {
      sessionStorage.setItem('access_token', token.accessToken);
      sessionStorage.setItem('refresh_token', token.refreshToken);
      sessionStorage.setItem('tokenType', token.tokenType);
      this.user.next(this.jwtService.decodeToken(token.accessToken));
    } else {
      this.user.next(null);
    }
  }

  public getTokenExpirationDate(): any {
    return this.jwtService.getTokenExpirationDate(this.accessToken);
  }

  public isAccessTokenExpired(): boolean {
    if (!this.accessToken) {
      return true;
    }
    return this.jwtService.isTokenExpired(this.accessToken);
  }

  public get isRefreshTokenExpired(): boolean {
    if (!this.refreshToken) {
      return true;
    }
    return this.jwtService.isTokenExpired(this.refreshToken);
  }

  public get accessToken(): any {
    const token = sessionStorage.getItem('access_token');
    if (!token || token === 'null') {
      return null;
    }
    return token;
  }

  public get refreshToken(): any {
    const token = sessionStorage.getItem('refresh_token');
    if (!token || token === 'null') {
      return null;
    }
    return token;
  }
}
