export * from './adminController.service';
import { AdminControllerService } from './adminController.service';
export * from './merchantController.service';
import { MerchantControllerService } from './merchantController.service';
export * from './profileController.service';
import { ProfileControllerService } from './profileController.service';
export * from './retailerController.service';
import { RetailerControllerService } from './retailerController.service';
export * from './userRetailerController.service';
import { UserRetailerControllerService } from './userRetailerController.service';
export * from './userShopController.service';
import { UserShopControllerService } from './userShopController.service';
export const APIS = [AdminControllerService, MerchantControllerService, ProfileControllerService, RetailerControllerService, UserRetailerControllerService, UserShopControllerService];
