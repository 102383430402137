import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, DEBUG_MODE, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgHttpLoaderModule } from 'ng-http-loader';

// SWAGGER LIBRARY IMPORTS
import { ApiModule as AdsSwaggerModule, BASE_PATH as ADS_SERVICE } from 'projects/ads-swagger-lib';
import { ApiModule as AuthSwaggerModule, BASE_PATH as AUTH_SERVICE } from 'projects/auth-swagger-lib';
import { ApiModule as BankAccountSwaggerModule, BASE_PATH as BANK_ACCOUNT_SERVICE } from 'projects/bank-account-swagger-lib';
import { ApiModule as CardSwaggerModule, BASE_PATH as CARD_SERVICE } from 'projects/card-swagger-lib';
import { ApiModule as IntegrationSwaggerModule, BASE_PATH as INTEGRATION_SERVICE } from 'projects/integration-swagger-lib';
import { ApiModule as LoyaltyCardSwaggerModule, BASE_PATH as LOYALTY_CARD_SERVICE } from 'projects/loyalty-card-swagger-lib';
import { ApiModule as PaymentSwaggerModule, BASE_PATH as PAYMENT_SERVICE } from 'projects/payment-swagger-lib';
import { ApiModule as ShopSwaggerModule, BASE_PATH as SHOP_SERVICE } from 'projects/shop-swagger-lib';
import { ApiModule as UploadSwaggerModule, BASE_PATH as UPLOAD_SERVICE } from 'projects/upload-swagger-lib';
import { ApiModule as UserSwaggerModule, BASE_PATH as USER_SERVICE } from 'projects/user-swagger-lib';
import { ApiModule as WalletSwaggerModule, BASE_PATH as WALLET_SERVICE } from 'projects/wallet-swagger-lib';
import { ApiModule as NotificationSwaggerModule, BASE_PATH as NOTIFICATION_SERVICE } from 'projects/notification-swagger-lib';
import { ApiModule as AppConfigSwaggerModule, BASE_PATH as APP_CONFIG_SERVICE } from 'projects/app-config-swagger-lib';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SharedService } from './services/shared.service';
import { NotificationService } from './services/notification.service';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';

export function tokenGetter(): string {
  return sessionStorage.getItem('access_token');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    AdsSwaggerModule,
    AuthSwaggerModule,
    BankAccountSwaggerModule,
    CardSwaggerModule,
    IntegrationSwaggerModule,
    LoyaltyCardSwaggerModule,
    PaymentSwaggerModule,
    ShopSwaggerModule,
    UploadSwaggerModule,
    UserSwaggerModule,
    WalletSwaggerModule,
    NotificationSwaggerModule,
    CreditCardDirectivesModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      positionClass: 'toast-bottom-full-width'
    }),
    JwtModule.forRoot({
      config: { tokenGetter }
    })
  ],
  providers: [
    {
      provide: ADS_SERVICE,
      useFactory: () => environment.baseUrlAdsService
    },
    {
      provide: AUTH_SERVICE,
      useFactory: () => environment.baseUrlAuthService
    },
    {
      provide: BANK_ACCOUNT_SERVICE,
      useFactory: () => environment.baseUrlBankAccountService
    },
    {
      provide: CARD_SERVICE,
      useFactory: () => environment.baseUrlCardService
    },
    {
      provide: INTEGRATION_SERVICE,
      useFactory: () => environment.baseUrlIntegrationService
    },
    {
      provide: LOYALTY_CARD_SERVICE,
      useFactory: () => environment.baseUrlLoyaltyCardService
    },
    {
      provide: PAYMENT_SERVICE,
      useFactory: () => environment.baseUrlPaymentService
    },
    {
      provide: SHOP_SERVICE,
      useFactory: () => environment.baseUrlShopService
    },
    {
      provide: UPLOAD_SERVICE,
      useFactory: () => environment.baseUrlUploadService
    },
    {
      provide: USER_SERVICE,
      useFactory: () => environment.baseUrlUserService
    },
    {
      provide: WALLET_SERVICE,
      useFactory: () => environment.baseUrlWalletService
    },
    {
      provide: NOTIFICATION_SERVICE,
      useFactory: () => environment.baseUrlNotificationService
    },
    {
      provide: APP_CONFIG_SERVICE,
      useFactory: () => environment.appConfigService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    SharedService,
    NotificationService,
    ScreenTrackingService,
    UserTrackingService,
    { provide: APP_NAME, useValue: 'admin-web-development' },
    { provide: APP_VERSION, useValue: environment.version },
    { provide: DEBUG_MODE, useValue: false }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
