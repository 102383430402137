export const environment = {
  showDevelopment: false,
  production: false,
  version: require('../../package.json').version,
  clientId: 'D3qWn2bVunJB46s',
  clientSecret: '4Cb7FVKUf2Ax93EqPj3MGG5dcqBRYA',
  baseUrlShopService: 'https://development-abcpay.qodix.dev/shop-service/api',
  baseUrlUserService: 'https://development-abcpay.qodix.dev/user-service/api',
  baseUrlIntegrationService: 'https://development-abcpay.qodix.dev/integration-service/api',
  baseUrlLoyaltyCardService: 'https://development-abcpay.qodix.dev/loyalty-card-service/api',
  baseUrlAuthService: 'https://development-abcpay.qodix.dev/auth-service/api',
  baseUrlPaymentService: 'https://development-abcpay.qodix.dev/payment-service/api',
  baseUrlAdsService: 'https://development-abcpay.qodix.dev/ads-service/api',
  baseUrlUploadService: 'https://development-abcpay.qodix.dev/upload-service/api',
  baseUrlBankAccountService: 'https://development-abcpay.qodix.dev/bank-account-service/api',
  baseUrlCardService: 'https://development-abcpay.qodix.dev/card-service/api',
  baseUrlWalletService: 'https://development-abcpay.qodix.dev/wallet-service/api',
  baseUrlNotificationService: 'https://development-abcpay.qodix.dev/notification-service/api',
  appConfigService: 'https://development-abcpay.qodix.dev/app-config-service/api',
  firebase: {
    apiKey: 'AIzaSyB9hbJfanVnNV3sNYm2NBAwzMHR2Mgz4E4',
    authDomain: 'abcpay-development.firebaseapp.com',
    projectId: 'abcpay-development',
    storageBucket: 'abcpay-development.appspot.com',
    messagingSenderId: '780779644297',
    appId: '1:780779644297:web:01f10eaf61dd9bc620ed58',
    measurementId: 'G-FV669WD0L0'
  }
};
