import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Spinkit } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { NotificationService } from './services/notification.service';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ABC Pay = User Portal';
  spinkit = Spinkit;
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor(
    private notificationService: NotificationService,
    private sharedService: SharedService,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {
    if (!sessionStorage.installationId) {
      sessionStorage.setItem('installationId', this.sharedService.generateRandomCharracters(64).substr(0, 64));
    }

    if (sessionStorage.installationId) {
      this.notificationService.requestPermission(false, true);
    }

    this.notificationService.receiveMessage();

    this.notificationService.currentMessage.subscribe(res => {
      if (res) {
        this.toastrService.success(res.notification.title, res.notification.body, { positionClass: 'toast-top-full-width', timeOut: 6000 });
      }
    });

    window.onbeforeunload = this.deactivateSocket();

    if (!localStorage.language) {
      localStorage.setItem('language', 'en');
    }

    translate.setDefaultLang(localStorage.language);
    translate.use(localStorage.language);
  }

  deactivateSocket() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      }).catch((err) => {
        console.log('Service Worker registration failed: ', err);
      });
    }
    return null;
  }

  ngOnInit(): any {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.toastrService.success('', 'Welcome back online', { positionClass: 'toast-top-full-width', timeOut: 3000 });
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.toastrService.error('', 'Seems that you are offline :(', { positionClass: 'toast-top-full-width', timeOut: 3000 });
    }));
  }

  onActivate(event): any {
    window.scroll(0, 0);
  }
}
