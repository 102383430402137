import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class SharedService {
  public registeredUser: Subject<any> = new BehaviorSubject<any>(null);

  constructor(
  ) {
  }

  public setRegisterUser(user) {
    this.registeredUser.next(user);
  }

  public findInvalidControls(form) {
    const invalid = [];
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  generateRandomCharracters(lengthOfCode: number): string {
    const possibleLetters = 'abcdefghijklmnopqrstuvwxyz1234567890';

    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possibleLetters.charAt(Math.floor(Math.random() * possibleLetters.length));
    }
    return new Date().getTime() + text;
  }
}
