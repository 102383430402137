export * from './adminController.service';
import { AdminControllerService } from './adminController.service';
export * from './currencyController.service';
import { CurrencyControllerService } from './currencyController.service';
export * from './employeeController.service';
import { EmployeeControllerService } from './employeeController.service';
export * from './feeController.service';
import { FeeControllerService } from './feeController.service';
export * from './merchantController.service';
import { MerchantControllerService } from './merchantController.service';
export * from './monitoringController.service';
import { MonitoringControllerService } from './monitoringController.service';
export * from './paymentRequestController.service';
import { PaymentRequestControllerService } from './paymentRequestController.service';
export * from './retailerController.service';
import { RetailerControllerService } from './retailerController.service';
export * from './transactionController.service';
import { TransactionControllerService } from './transactionController.service';
export const APIS = [AdminControllerService, CurrencyControllerService, EmployeeControllerService, FeeControllerService, MerchantControllerService, MonitoringControllerService, PaymentRequestControllerService, RetailerControllerService, TransactionControllerService];
