export * from './adminController.service';
import { AdminControllerService } from './adminController.service';
export * from './employeeController.service';
import { EmployeeControllerService } from './employeeController.service';
export * from './merchantController.service';
import { MerchantControllerService } from './merchantController.service';
export * from './retailerController.service';
import { RetailerControllerService } from './retailerController.service';
export * from './shopController.service';
import { ShopControllerService } from './shopController.service';
export const APIS = [AdminControllerService, EmployeeControllerService, MerchantControllerService, RetailerControllerService, ShopControllerService];
